body {
  background-color: #f5f6fa;
}

.navbar-menu .nav-item.with-sub .nav-link::after {
  margin-top: -2px;
}

.inline {
  display: inline;
}

.filled {
  fill: currentColor;
}

.navbar-header .btn-buy span {
    margin-left: 0px;
}

/* FIXES */

.filemgr-wrapper {
  /* position: absolute; */
}

.filemgr-content-body{
  overflow-y: scroll;
}

/* SEARCH */

ul.search-results {
  display: block;
  list-style: none;
  padding: 0;
}

ul.search-results li {
  display: block;
  width: 100%;
  margin: 0 0 15px 0;
}

ul.search-results li a {
  border-radius: 0;
  padding: 5px;
  font-size: 16px;
}


/* COLORS */

.tx-ms-blue {
  color: #0168fa;
}


/* SVG */


.svg-checkmark {
  stroke: green;
  stroke-width: 2;
  stroke-dashoffset: 745.74853515625;
  stroke-dasharray: 745.74853515625;
  animation: dash 2s ease-out forwards 1;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 745.74853515625;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.invalid-feedback {
  display: block;
}

/* needed for mobile nav */
.filemgr-sidebar{
  z-index: 60;
}